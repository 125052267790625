<template>
  <div class="activity">
    <div class="activity-cnter">
      <div class="center-title">{{ detailData.title }}</div>
      <div v-html="detailData.content"></div>
      <div class="flex-center" v-if="detailData.qrcode_url">
        <img :src="detailData.qrcode_url" class="el-img-activity" alt="" />
      </div>
      <div class="flex-center"  v-if="detailData.qrcode_url">
        <div class="el-activity-price">
          <span>支付金额</span>
          <span>{{ detailData.price }}</span>
        </div>
      </div>
      <div class="flex-center">
        <div class="el-activity-text">
          <span>套餐内容：</span>
          &nbsp;
          <span>人才推送次数</span>
          &nbsp;
          <span class="text-blue">{{ detailData.push_num }}次</span>
          &nbsp;&nbsp;
          <span>置顶次数</span>
          &nbsp;
          <span class="text-blue">{{ detailData.top_num }}次</span>
          &nbsp;&nbsp;
          <span>刷新次数</span>
          &nbsp;
          <span class="text-blue">{{ detailData.refresh_num }}次</span>
          &nbsp;&nbsp;
          <span>急招次数</span>
          <span class="text-blue">{{ detailData.urgency_num }}次</span>
          &nbsp;&nbsp;
          <span>赠送纺职豆</span>
          &nbsp;
          <span class="text-blue">{{ detailData.send_coin }}个</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/system";
export default {
  components: {},

  data() {
    return {
      detailData: null,
    };
  },

  created() {
    let id = this.$route.query.id;
    if (id) {
      this.activityDetail(id);
    }
  },

  mounted() {},

  methods: {
    activityDetail(id) {
      api.activityDetail({ id }).then((res) => {
        this.detailData = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.activity {
  width: 100%;
  padding: 1.25rem 9.1875rem 0 9.1875rem;
  .activity-cnter {
    width: 100%;
    padding: 1.25rem 1.875rem 0.625rem 1.875rem;
    background: #fff;
    .center-title {
      text-align: center;
      font-size: 30px;
    }
    .el-img-activity {
      margin: 10px 0;
      width: 200px;
      height: 200px;
    }
    .el-activity-price,
    .el-activity-text {
      margin-top: 20px;
    }
  }
}
</style>
